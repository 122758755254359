/* jshint esversion: 8 */
define([
    "underscore",
    "jquery",
    "lit-element/lit-element.bundled",
    "components/cloudCenterElement",
    "util",
    "dojo/i18n!nls/cloudCenterStringResource",
    "dojo/string",
    "validation/inlineComponentValidation",
    "components/inlinePopover",
    "service/components/machineTypeChooserWithFinderByLocation",
    "service/components/machineTypeChooserWithFinderByMachineType",
    "service/components/machineTypeChooserWithFinderByExistingResource",
    "config",
    "service/cloudCenterDataService",
    "dao/cloudCenterDao",
    "dialogs/wizardWarningDialog",

], function (
    _,
    $,
    Lit,
    CloudCenterElement,
    Util,
    I18NStringResource, DojoString,
    InlineComponentValidation,
    InlinePopover,
    ServiceByLocation,
    ServiceByMachineType,
    ServiceByExistingResource,
    GCONFIG,
    CloudCenterDataService,
    CloudCenterDao,
    WizardWarningDialog,
) {

    class MachineTypeChooserWithFinder extends CloudCenterElement {

        constructor() {
            super();
        }

        cceInitialize() {
            super.cceInitialize();
            Util.consoleLogTrace('MachineTypeChooserWithFinder`, `cceInitialize invoked');
            // Default values
            this.elementid = "";
            this.elementlabel = " ";
            this.poptext = "";
            this.dduxenabled = false;
            this.vpcid = "";
            this.vpclabel = "";
            this.vpcpoptext = "";
            this.vpcplaceholder = "";
            this.vpcvalue = "";
            this.subnetid = "";
            this.subnetlabel = "";
            this.subnetpoptext = "";
            this.subnetplaceholder = "";
            this.subnetvalue = "";
            this.machinetypeid = "";
            this.machinetypelabel = "";
            this.machinetypepoptext = "";
            this.machinetypeplaceholder = "";
            this.machinetypevalue = "";

            this.loadingRules = false;



            /* istanbul ignore next */
            this.getCurrentCredentialId = () => "";
            /* istanbul ignore next */
            this.getRulesId = () => "";
            /* istanbul ignore next */
            this.getCurrentLocation = () => "";
            /* istanbul ignore next */
            this.getSavedStep1Values = () => "";
            /* istanbul ignore next */
            this.getSavedStep2ValueOverrides = () => "";  // this is the data passed from Step 2 to Step 1 so it can come back
            /* istanbul ignore next */
            this.getCurrentStep2ValuesMethod = () => "";  // this is the data passed from Step 1 to Step 2
            this.getCurrentPage = () => "";
            this.getCredentialTypeId = () => "";


            /* istanbul ignore next */
            this.updateSavedStep2ValueOverride = (id, value) => "";
            /* istanbul ignore next */
            this.updateSavedStep1Value = (id, value) => "";

            /* istanbul ignore next */
            this.onStep1Change = () => "";


            /* istanbul ignore next */
            this.getCurrentCredentialId = () => "";

            const cloudCenterDao = new CloudCenterDao(GCONFIG);
            this._dataService = new CloudCenterDataService({ dao: cloudCenterDao });

            // setup debounced event handler so it only gets called once per flurry of same events
            // Both vpc and subnet selects will receive "optionsLoaded" events. This tries to
            // update the UI once after the last event (assuming they all come in within a second).
            this._debouncedOptionsLoaded = _.debounce(this._onOptionsLoaded, 1000).bind(this);


        }

        // Reactive Properties -- updates rendering when values change
        static get properties() {
            return {
                elementid: { type: String },
                elementlabel: { type: String },
                poptext: { type: String },
                dduxenabled: { type: Boolean },
                vpcid: { type: String },
                vpclabel: { type: String },
                vpcpoptext: { type: String },
                vpcplaceholder: { type: String },
                vpcvalue: { type: String },
                subnetid: { type: String },
                subnetlabel: { type: String },
                subnetpoptext: { type: String },
                subnetplaceholder: { type: String },
                subnetvalue: { type: String },
                machinetypeid: { type: String },
                machinetypelabel: { type: String },
                machinetypepoptext: { type: String },
                machinetypeplaceholder: { type: String },
                machinetypevalue: { type: String }
            };
        }

        _getCoreProperties() {
            return {
                vpcid: this.vpcid,
                subnetid: this.subnetid,
                machinetypeid: this.machinetypeid,
                elementid: this.elementid,
                elementlabel: this.elementlabel,
                poptext: this.poptext,
                dduxenabled: this.dduxenabled,
                vpclabel: this.vpclabel,
                vpcpoptext: this.vpcpoptext,
                vpcplaceholder: this.vpcplaceholder,
                vpcvalue: this.vpcvalue,
                subnetlabel: this.subnetlabel,
                subnetpoptext: this.subnetpoptext,
                subnetplaceholder: this.subnetplaceholder,
                subnetvalue: this.subnetvalue,
                machinetypelabel: this.machinetypelabel,
                machinetypepoptext: this.machinetypepoptext,
                machinetypeplaceholder: this.machinetypeplaceholder,
                machinetypevalue: this.machinetypevalue
            }
        }

        // Called after initial rendering
        firstUpdated() {
            Util.consoleLogTrace(`MachineType.firstUpdated`, `invoked`);
        }

        validate(testTargetId, testTargetTag) {
            Util.consoleLogTrace(`MachineType.validate`, `invoked with ${testTargetTag}#${testTargetId}`);
            let isValid = true;
            let [vpc, subnet, machineType] = this._getAllInputElements();

            if (!vpc || !subnet || !machineType) {
                isValid = false;
            }

            return isValid;
        }


        _getDataService() {
            return this._dataService;
        }


        async _refreshRules(refresh) {
            if (this._rules && !refresh) {
                return;
            }

            if (this.loadingRules && !refresh) {
                // anything calling this is just waiting for the rules to load
                // so just let it be
                for (let i = 0; i < 20; i++) { // wait up to 2 seconds
                    await new Promise(resolve => setTimeout(resolve, 100)) // sleep 100ms
                    if (!this.loadingRules) {
                        return;
                    }
                }
            }

            this.loadingRules = true;
            const rulesId = this.getRulesId();
            const location = this.getCurrentLocation();
            const credentialId = this.getCurrentCredentialId();
            const rulesList = await this._getDataService().workflow.getRuleByIdWithDefaults(rulesId, location, credentialId);
            this._rules = rulesList[0]
            this._defaults = {
                vpc: this.vpcvalue,
                subnet: this.subnetvalue,
                machinetype: this.machinetypevalue
            }
            if (!this._defaults.vpc || !this._defaults.subnet || !this._defaults.machinetype) {
                for (const param of this._rules.params.body.params) {
                    if (param.id === this.vpcid && !this._defaults.vpc) {
                        this._defaults.vpc = param.config.default;
                    } else if (param.id === this.subnetid && !this._defaults.subnet) {
                        const defaultSubnet = param.config.default !== "Subnet" ? param.config.default : "" //there's a bug where this returns "Subnet" if no user pref
                        this._defaults.subnet = defaultSubnet;
                    } else if (param.id === this.machinetypeid && !this._defaults.machinetype) {
                        this._defaults.machinetype = param.config.default;
                    }
                }
            }
        }

        _getRules() {
            return this._rules;
        }

        async _getDefaults() {
            await this._refreshRules();
            return this._defaults
        }

        _selectedService() {
            const selectedRadioButton = this.renderRoot.querySelector('input[name="findByChoice"]:checked')
            if (!selectedRadioButton) {
                const applyBtn = this.renderRoot.querySelector('button#applyMachineAndNetworkPickerChoices');
                if (applyBtn) {
                    // applyBtn.disabled = true;
                    // applyBtn.classList.add("disabled")
                }
                return;
            }

            switch (selectedRadioButton.dataset.findby) {
                case "byLocation":
                    return this.serviceByLocation;
                case "byMachineType":
                    return this.serviceByMachineType;
                case "byExistingResource":
                    return this.serviceByExistingResource;
            }

            throw new Error(`MachineType._selectedService: unknown service ${selectedRadioButton.dataset.findby}`);
        }

        _applyChoices(event) {
            if (event && event.preventDefault) {
                event.preventDefault();
            }
            const selService = this._selectedService();
            if (selService) {
                try {
                    const [navWarning, navPrompt] = selService.selectionRequiresStep1Change()
                    if (navWarning && typeof navWarning === "string") {
                        this.showWarningDialog(navWarning, navPrompt, function () {
                            // step2.getCurrentStep2Values() iterates through the elements, so this will allow the step 2 values to be updated
                            // only do this inside the confirmation because else there's no navigation, so we shouldn't set the values
                            this._setValuesIntoInputElements(selService)

                            selService.updateStepsAndNavigate()
                        }.bind(this));
                        return
                    }

                    this._setValuesIntoInputElements(selService)

                } catch (error) {
                    Util.consoleLogError(`MachineType._applyChoices`, `error: ${error}`);
                }
            }
        }

        _cancelChoices(event) {
            if (event && event.preventDefault) {
                event.preventDefault();
            }
            this._toggleEditUI();
        }

        _editChoices(event) {
            if (event && event.preventDefault) {
                event.preventDefault();
            }
            this._toggleEditUI();
        }

        _toggleEditUI() {
            const editContainer = this.renderRoot.querySelector('#editChoicesContainer');
            const editButtonContainer = this.renderRoot.querySelector('#editButtonContainer');
            if (editContainer && editButtonContainer) {
                editContainer.classList.toggle('hidden');
                editButtonContainer.classList.toggle('hidden');
            }
        }

        _setValuesIntoInputElements(service) {
            let [vpc, subnet, machineType] = this._getAllInputElements();
            let [newVPC, newSubnet, newMachineType] = service.getSelectedData();

            if (vpc) {
                vpc.value = newVPC;
            }
            if (subnet) {
                subnet.value = newSubnet;
            }
            if (machineType) {
                machineType.value = newMachineType;
            }
        }
        _toggleApplyButton(event) {
            let disabled = true;
            const selService = this._selectedService();
            if (selService) {
                let [vpc, subnet, machineType] = selService.getSelectedData();
                if (vpc && subnet && machineType) {
                    disabled = false;
                }

            }

            const applyBtn = this.renderRoot.querySelector('button#applyMachineAndNetworkPickerChoices');
            if (applyBtn) {
                applyBtn.disabled = disabled;
                if (disabled) {
                    applyBtn.classList.add("disabled")
                } else {
                    applyBtn.classList.remove("disabled")
                }
            }
        }




        _onChangeHandler(event) {
            //
        }

        _onOptionsLoaded(event) {
            Util.consoleLogTrace(`_onOptionsLoaded`, `calling _updateUI`);
            this._updateUI();
        }

        _updateUI(event) {
            let disabled = ["byLocation", "byMachineType", "byExistingResource"];
            let enabled = [];


            const choice = this.renderRoot.querySelector('input[name="findByChoice"]:checked').dataset.findby;
            for (let i = 0; i < disabled.length; i++) {
                if (disabled[i] === choice) {
                    disabled.splice(i, 1);
                    enabled.push(choice);

                    this.renderRoot.querySelectorAll(`div.section2InputContainer`).forEach((element) => {
                        element.hidden = (element.dataset.findby && element.dataset.findby !== choice);
                    });
                    break;
                }
            }

            this.serviceByLocation.setRadioSelected(choice === "byLocation");
            this.serviceByMachineType.setRadioSelected(choice === "byMachineType");
            this.serviceByExistingResource.setRadioSelected(choice === "byExistingResource");

            for (const elementTagName of ['input', 'button', 'select']) {
                for (const className of disabled) {
                    this.renderRoot.querySelectorAll(`div.radioButtonContainer.${className} ${elementTagName}`).forEach((element) => {
                        if (element.type !== "radio") {
                            element.disabled = true;
                            element.classList.add('disabled');
                        }
                    });
                }

                for (const className of enabled) {
                    this.renderRoot.querySelectorAll(`div.radioButtonContainer.${className}  ${elementTagName}`).forEach((element) => {
                        if (element.type !== "radio") {
                            element.disabled = false;
                            element.classList.remove('disabled');
                        }
                    });
                }
            }

            this._toggleApplyButton();

        }

        _getAllInputElements() {
            const vpc = this.renderRoot.querySelector(`input#${this.vpcid}`);
            const subnet = this.renderRoot.querySelector(`input#${this.subnetid}`);
            const machineType = this.renderRoot.querySelector(`input#${this.machinetypeid}`);
            return [vpc, subnet, machineType];
        }

        showWarningDialog(mainWarning, proceedPrompt, proceedMethod) {
            var dialogTitle = I18NStringResource.wizardWarningDialogTitle;
            //show modal dialog for confirmation
            let wizardWarningModalDialog = new WizardWarningDialog({
                title: dialogTitle,
                text: mainWarning,
                prompt: proceedPrompt,
                actionFn: proceedMethod.bind(this)
            });
            wizardWarningModalDialog.show();
        }

        _renderCurrentlySelected() {
            if (this.vpcvalue === '[object Object]') {
                this.vpcvalue = 'Loading...'
            }
            if (this.machinetypevalue === '[object Object]') {
                this.machinetypevalue = 'Loading...'
            }
            if (this.subnetvalue === '[object Object]') {
                this.subnetvalue = 'Loading...'
            }
        }

        _coreMethods() {
            return {
                getCurrentCredentialId: this.getCurrentCredentialId,
                getCurrentLocation: this.getCurrentLocation,
                getCurrentPage: this.getCurrentPage,

                updateSavedStep1Value: this.updateSavedStep1Value,
                updateSavedStep2ValueOverride: this.updateSavedStep2ValueOverride,
                onStep1Change: this.onStep1Change,

                refreshRules: this._refreshRules.bind(this),
                getRules: this._getRules.bind(this),

                getCSSClassFromLabelText: this.getCSSClassFromLabelText.bind(this), // this is from cloudcenterElement.js

                getDefaults: this._getDefaults.bind(this),
                updateUI: this._updateUI.bind(this),
                logClick: this._logClick.bind(this),
                debouncedOptionsLoaded: this._debouncedOptionsLoaded.bind(this),
                toggleApplyButton: this._toggleApplyButton.bind(this),
                applyChoices: this._applyChoices.bind(this)
            }
        }

        applyInitialValues() {
            if (this.serviceByLocation.isInitialLoad()) {
                setTimeout(() => this.applyInitialValues(), 100);
                return;
            }

            this._setValuesIntoInputElements(this.serviceByLocation);
        }

        // Render element template
        render() {
            // Util.consoleLogInfo('render', `Starting Data: ${this.vpcvalue} ${this.subnetvalue} ${this.machinetypevalue}`, "step 1", JSON.stringify(this.getSavedStep1Values(),null ,2), "step 2", JSON.stringify(this.getSavedStep2ValueOverrides(),null ,2));

            this._refreshRules(); //load the defaults
            const coreMethods = this._coreMethods();

            this.serviceByLocation = new ServiceByLocation({
                root: this.renderRoot,
                props: this._getCoreProperties(),
                methods: { core: coreMethods, service: {} },
                currentPage: this.getCurrentPage(),

                timestamp: this.timestamp
            })

            this.serviceByMachineType = new ServiceByMachineType({
                root: this.renderRoot,
                props: this._getCoreProperties(),
                credentialTypeId: this.getCredentialTypeId(),
                rulesId: this.getRulesId(),
                methods: { core: coreMethods, service: {} },
                currentPage: this.getCurrentPage(),
                timestamp: this.timestamp
            })

            this.serviceByExistingResource = new ServiceByExistingResource({
                root: this.renderRoot,
                props: this._getCoreProperties(),
                methods: { core: coreMethods, service: {} },
                currentPage: this.getCurrentPage(),
                timestamp: this.timestamp
            })

            this.applyInitialValues();


            return Lit.html`
                <h2 class="machineTypeChooserHeader">${I18NStringResource.machineTypeFinderSummaryTitle}</h2>

                <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer">
                    <label for="MachineFinder${this.machinetypeid}">${this.machinetypelabel}</label>
                    <div class="section2InputValidationContainer">
                        <input type="text" name="${this.machinetypeid}" id="${this.machinetypeid}" class="disabled fullWidth" readonly value="${this.machinetypevalue}" />
                        <inline-validation elementid="${this.machinetypeid}" />
                    </div>
                </div>

                <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer">
                    <label for="MachineFinder${this.vpcid}">${this.vpclabel}</label>
                    <div class="section2InputValidationContainer">
                        <input type="text" name="${this.vpcid}" id="${this.vpcid}" class="disabled fullWidth" readonly value="${this.vpcvalue}" />
                        <inline-validation elementid="${this.vpcid}" />
                    </div>
                </div>

                <div class="sectionContents"><fieldset class="section2Group"><div class="section2InputContainer">
                    <label for="MachineFinder${this.subnetid}">${this.subnetlabel}</label>
                    <div class="section2InputValidationContainer">
                        <input type="text" name="${this.subnetid}" id="${this.subnetid}" class="disabled fullWidth" readonly value="${this.subnetvalue}" />
                        <inline-validation elementid="${this.subnetid}" />
                    </div>
                </div>

                <div id="editButtonContainer" class="row">
                    <div class="col editChoicesButtonContainer">
                        <button id="editChoices" type="button" class="btn btn_color_blue add_indent_10"
                            title="${I18NStringResource.machineTypeFinderEditChoicesTooltip}"
                            @click="${this._editChoices}"
                            >${I18NStringResource.machineTypeFinderEdit}</button>
                    </div>
                </div>

                <div id="editChoicesContainer" class="editChoicesContainer hidden">
                    ${this.serviceByMachineType.render()}

                    ${this.serviceByLocation.render()}

                    ${this.serviceByExistingResource.render()}

                    <div class="buttonContainer stepComponentContainer">
                        <button id="cancelMachineAndNetworkPickerChoices" type="button" class="btn btn_color_blue companion_btn add_indent_10"
                            title="${I18NStringResource.machineTypeFinderCancelEdit}"
                            @click="${this._cancelChoices}"
                            >${I18NStringResource.machineTypeFinderCancel}</button>
                        <button id="applyMachineAndNetworkPickerChoices" type="button" class="btn btn_color_blue add_indent_10 disabled" disabled
                            title="${I18NStringResource.machineTypeFinderApplyCurrentSelection}"
                            @click="${this._applyChoices}"
                            >${I18NStringResource.machineTypeFinderApply}</button>
                    </div>
                </div>
              `


        }
    }

    // Register custom element '<machine-type-chooser-with-finder />'
    customElements.define('machine-type-chooser-with-finder', MachineTypeChooserWithFinder);

    return MachineTypeChooserWithFinder;
});
