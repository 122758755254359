/* jshint esversion: 11 */
define([
    "dao/abstractDao",
    "dojo/i18n!nls/cloudCenterStringResource",
    "dojo/string",
    "util"
  ], function( AbstractDAO, I18NStringResource, DojoString, Util ) {

    const USER_ABORTED = 20;
    const TIMEOUT_ABORTED = 23;

    /**
     * Implementation of AbstractDAO interface using Cloud Center Microservice.
     * Uses config object to get needed URL Parameters
     *
     * throws TypeError if config is missing or not what is expected.
     */
    class CloudCenterFetchDAO extends AbstractDAO {
      constructor(config) {
        if (
            !config || typeof config !== 'object' ||
            !config.getMicroServiceURL ||
            typeof config.getMicroServiceURL !== "function" ||
            !config.getAWSAccountID ||
            typeof config.getAWSAccountID !== "function" ||
            typeof config.getLegacyParallelServicesURL !== "function"
        ) {
            throw new TypeError("Incompatible config file parameter.");
        }
        super();
        /* istanbul ignore next */
        const useFakeLogin = (config.isFakeLoginEnabled && typeof config.isFakeLoginEnabled === "function" && config.isFakeLoginEnabled());
        /* istanbul ignore next */
        const fakeUserName = (useFakeLogin && config.getFakeUser && typeof config.getFakeUser === "function") ? config.getFakeUser() : "";
        this.config = config;
        this.authToken = null;
        this.sessionId = null;
        this.loginData = null;
        this.originId = null;
        this.sessionListener = null;
        this.microServiceBaseUrl = config.getMicroServiceURL();
        this.awsAccountID = config.getAWSAccountID();
        this.legacyParallelServicesURL = config.getLegacyParallelServicesURL();
        this.useFakeLogin = useFakeLogin;
        this.fakeUserName = fakeUserName;
        this.clientString = "cloudcenter";
        this.abortController = null;
        this.sessionExpiredCallQueue = [];
        this.queryQueue = [];
        this.queryWaiting = false;

        this.initialize();
      }

      initialize() {
        if (Util.fetchAbortSupported()) {
          this.abortController = new AbortController();
        }
        // Right now we only use HTML5 local storage. If that's not supported, we do no storing of values.
        /* istanbul ignore next */
        this.htmlLocalStorage = ( (typeof(Storage) !== "undefined") &&
                                  (typeof(localStorage) === "object") &&
                                  (typeof(localStorage.getItem) === "function") ) ? true : false;  // can we use HTML5 local (or session) storage?
      }

      getBaseURL () { return this.microServiceBaseUrl; }
      getLegacyParallelServicesURL () { return this.legacyParallelServicesURL; }
      getAWSAccountID () { return this.awsAccountID; }
      isFakeLoginEnabled () { return this.useFakeLogin; }
      getFakeUser () { return this.fakeUserName; }
      setSessionListener (listener) { this.sessionListener = listener; }
      getSessionListener () { return this.sessionListener; }
      setAuthToken (authToken) { this.authToken = authToken; }
      setSessionId (sessionId) { this.sessionId = sessionId; }
      getAuthToken () { return this.authToken; }
      setOriginId (originId) { this.originId = originId; }
      getSessionId () { return this.sessionId; }
      getOriginId () { return this.originId; }
      getLoginData () { return this.loginData; }
      setLoginData (data) { this.loginData = data; }
      getUserId () { return (this.getLoginData()) ? this.getLoginData().userID : ""; }
      setUserId (userId) { /* do nothing */ }
      setClientString (clientString) { this.clientString = clientString; }
      getClientString () { return this.clientString; }
      getAbortController () { return Util.fetchAbortSupported() ? this.abortController : null; }
      setAbortController (controller) { this.abortController = controller; }
      abortAllFetchCalls () {
        if (Util.fetchAbortSupported()) {
          this.getAbortController().abort();
          this.setAbortController(new AbortController());
        }
      }
      useHtmlLocalStorage () {
        return this.htmlLocalStorage;
      }

      enqueue (item) { return this.sessionExpiredCallQueue.push(item); }
      dequeue () { return this.sessionExpiredCallQueue.shift(); }
      queueSize () { return this.sessionExpiredCallQueue.length; }
      setQueryFlag () { this.queryWaiting = true; }
      clearQueryFlag () { this.queryWaiting = false; }
      queryFlagIsSet () { if (this.queryWaiting) { return true; } else { return false; } }

      async processNextQueryInQueue () {
        if (this.queryQueueSize() > 0) {
          const nextItem = this.dequeueQuery();
          const fetchUrl = nextItem.url;
          const fetchArgs = nextItem.fetchArgs;
          const promise = nextItem.promise;
          await this.performFetch(fetchUrl, fetchArgs, promise);
        } else {
          this.clearQueryFlag();
        }
      }
      enqueueQuery (item) {
        if (!this.queryQueue.some(function(x) { return x === item;})) {
          item.promise.always(this.processNextQueryInQueue.bind(this));
          this.queryQueue.push(item);
        }
        return this.queryQueueSize();
      }
      dequeueQuery () { return this.queryQueue.shift(); }
      queryQueueSize () { return this.queryQueue.length; }
      resetQueryQueue () { this.queryQueue = []; }

      toEscapedURL (url, path, queryParams) {
        if (!url) {
          throw new TypeError("Invalid url argument");
        }
        let encodedPath = (path? encodeURIComponent(path) : '');
        if (encodedPath) {
          encodedPath = encodedPath.replace(/%2F/gi,'/');
          if (encodedPath.charAt(0) !== "/") {
            encodedPath = "/" + encodedPath;
          }
        }
        let fullURL = encodeURI(url);
        if (encodedPath) {
          fullURL = fullURL + encodedPath;
        }
        /* istanbul ignore next */
        queryParams = queryParams || {};
        // Add usage metrics support
        queryParams.clientString = this.getClientString();
        const queryString = this.getQueryStringFromQueryParams(queryParams);
        if (queryString) {
            fullURL = fullURL + "?" + queryString;
        }
        return fullURL;
      }

      getQueryStringFromQueryParams (queryParams) {
        // Turn each property of queryParams into list of "[key]=[value]" strings, where both [key] and [value]
        // have been URL encoded
        const pairs = Util.propertyPairs(queryParams);
        const parts = pairs.map(pair => { return encodeURIComponent(pair[0]) + "=" + encodeURIComponent(pair[1]); });
        // Join the list together, delimiting each pair with a "&"
        const queryString = parts.join("&");
        return queryString;
      }

      getFilteredOptions (options) {
        let filteredOptions = null;
        /* setup options */
        // options are either passed in, or use the default values
        let defaults = {
            url: null,
            useHeaders: true,
            typeValue: 'get',
            dataType: 'json',
            timeout: 180000,
            data: {},
            cache: false,
            queryParams: {},
            contentType: 'application/json; charset=utf-8',
            accepts: 'application/json',
            restParams: {
              fetchAbortable: false,
              fetchTimeoutMillis: Util.defaultTimeout()
            }
        };
        if (options && typeof options === 'object') {
          if (options.typeValue && typeof options.typeValue === 'string') {
            options.typeValue = options.typeValue.toUpperCase();
          }
          filteredOptions = {...defaults, ...options}; // Merge objets: override defaults with options
        } else {
          filteredOptions = defaults;
        }
        // Add client APS originId
        if (this.getOriginId()) {
          filteredOptions.queryParams.originId = this.getOriginId();
        }
        return filteredOptions;
      }

      setupFetchHeadersAndOptions (fetchArgs, rawOptions) {
        if (!fetchArgs || typeof fetchArgs !== "object" || Object.keys(fetchArgs).length === 0) {
          throw new TypeError("Invalid fetchArgs argument object parameter");
        }
        /* istanbul ignore next */
        let options = rawOptions || {};
        /* setup headers */
        let headers = {};
        if ('accepts' in options && options.accepts) {
          headers.Accept = options.accepts;
        }
        if ('contentType' in options && options.contentType) {
          headers['Content-Type'] = options.contentType;
        }
        if ('customHeaderName' in options && 'customHeaderValue' in options) {
          headers[options.customHeaderName] = options.customHeaderValue;
        }
        if ('authToken' in options) {
          headers['x-mw-authentication'] = options.authToken;
        }
        if (this.isFakeLoginEnabled() && this.getFakeUser().length) {
          headers['x-mw-fakelogin'] = this.getFakeUser();
        }
        if (options.useHeaders) {
          fetchArgs.headers = headers;
        }
        if (options.dataType) {
          fetchArgs.dataType = options.dataType;
        }
        if ('processData' in options) {
          fetchArgs.processData = options.processData;
        }
        if (options.mode) {
          fetchArgs.mode = options.mode;
        }
      }

      async extractErrorInfo (errObj, errType, errText, fetchArgs) {
        // defaults
        let errorInfo = {errorCode: '', message: '', status: 0};
        // Extraact the error object from the server response.
        // It contains an error code and a text message.
        // If not error object, use the more generic passed in error text.
        /* istanbul ignore next */
        let text = errText || '';
        let errorCode = null;
        let wasAborted = false;
        if (errObj &&  typeof errObj === "object" && errObj.name === 'AbortError') {
          errorCode = errObj.code; // 20
          wasAborted = true;
          if (fetchArgs && fetchArgs.signal && fetchArgs.signal.reason && fetchArgs.signal.reason.name === 'TimeoutError') {
            errorCode = fetchArgs.signal.reason.code; // 23
          }
        }
        // get status, if any
        if (errObj && typeof errObj === "object" && errObj instanceof Response) {
          errorInfo.status = errObj.status;
        }
        // get body contents, if any
        let errorContent = null;
        if (errType === 'JSON' && errObj instanceof Response && !errObj.bodyUsed) {
          errorContent = await errObj.json();
        }
        // adjust message based on errorCode
        [ text, errorCode ] = this.getErrorTextAndCodeFromContents(errorContent, text, errorCode);
        // set the errorCode and adjust the message based on the code
        errorInfo.errorCode = errorCode;
        errorInfo.message = this.getErrorMessageFromCode(errorCode, text, wasAborted);
        return errorInfo;
      }

      getErrorMessageFromCode (errorCode, defaultText, wasAborted = false) {
        let message;
        if (errorCode !== 'SESSION_EXPIRED' && errorCode !== 'SESSION_NOT_FOUND' && !defaultText && wasAborted) {
          message = I18NStringResource.actionAborted;
        } else {
          message = defaultText;
        }
        return message
      }

      getErrorTextAndCodeFromContents (errorContent, defaultText, defaultErrorCode) {
        let text = defaultText, errorCode = defaultErrorCode;
        if (errorContent && errorContent.errors && errorContent.errors.length) {
          let error = errorContent.errors[0];
          text = error.message;
          errorCode = error.code;
        } else if (errorContent && Array.isArray(errorContent) && errorContent.length) {
          let error = errorContent[0];
          text = error;
          errorCode = error;
          if (errorCode === `core.aws.iam.error.invalidclienttokenid`) {
            text = I18NStringResource.AWSCreateRole_invalidTokens;
          } else if (errorCode === `core.aws.iam.error.expiredtoken`) {
            text = I18NStringResource.AWSCreateRole_expiredTokens;
          }
        } else if (errorContent && errorContent.err) {
          text = JSON.stringify(errorContent.err); // JSON.stringify needed here
          errorCode = "UNKNOWN";
        } else {
          if (defaultText.indexOf("fetch") > 0) {
            errorCode = "FETCH_FAILURE";
          }
        }
        return [ text, errorCode ];
      }

      async retryQueuedCalls () {
        let callItem = null;
        let currentSessionId = this.getSessionId();
        if (currentSessionId) {
          while (callItem = this.dequeue()) {
            if (callItem && callItem.fetchArgs && Object.keys(callItem.fetchArgs).length !== 0 && callItem.fetchArgs.headers) {
              // update sessionId in headers before resending
              if (callItem.fetchArgs.headers['x-mw-gds-session-id']) {
                callItem.fetchArgs.headers['x-mw-gds-session-id'] = currentSessionId;
              }
              // rerun failed call with updated headers
              const fetchUrl = callItem.url;
              const fetchArgs = callItem.fetchArgs;
              const promise = callItem.promise;
              await this.performFetch(fetchUrl, fetchArgs, promise);
            }
          }
        }
      }

      updateLoginInformation (sessionRenewalData) {
        if (sessionRenewalData && (typeof sessionRenewalData === "object")) {
          if (sessionRenewalData.sessionId) {
            this.setSessionId(sessionRenewalData.sessionId);
          }
          if (("loginProfile" in sessionRenewalData) && sessionRenewalData.loginProfile) {
            if (sessionRenewalData.loginProfile.mwaToken) {
              this.setAuthToken(sessionRenewalData.loginProfile.mwaToken);
            }
            this.setLoginData({
              firstName: sessionRenewalData.loginProfile.firstName,
              lastName: sessionRenewalData.loginProfile.lastName,
              userID: sessionRenewalData.loginProfile.userId,
              emailAddress: sessionRenewalData.loginProfile.emailAddress,
              token: sessionRenewalData.loginProfile.mwaToken
            });
          }
        }
      }

      clearLoginInformation () {
        this.setAuthToken(null); // assume authToken no good.
        this.setSessionId(null);
        this.setLoginData(null);
      }

      async handleFetchError (errObj, errType, errText, fetchArgs) {
        if (!fetchArgs || typeof fetchArgs !== "object" || Object.keys(fetchArgs).length === 0) {
          throw new TypeError("Invalid fetchArgs argument");
        }
        let errorInfo = await this.extractErrorInfo(errObj, errType, errText, fetchArgs);
        if (errorInfo && (errorInfo.errorCode === 'AUTH_TOKEN_EXPIRED')) {
          this.clearLoginInformation();
          // Notify those interested that our auth token is probably expired (auth manager)
          // cause a login to happen
          if (this.sessionListener) {
            this.sessionListener.trigger('AUTH_TOKEN_EXPIRED');
          }
          throw errorInfo;
        } else if (errorInfo && (errorInfo.errorCode === 'SESSION_EXPIRED' || errorInfo.errorCode === 'SESSION_NOT_FOUND')) {
          // try to renew session and if successful, retry original request.
          // If not successful, reject original request.
          // Notify those interested that our auth token is probably expired (auth manager)
          // cause a login to happen
          if (this.sessionListener) {
            this.sessionListener.trigger('AUTH_TOKEN_EXPIRED');
          }
          throw errorInfo;
        } else {
          if (fetchArgs.signal && fetchArgs.signal.aborted) {
            if (errorInfo.errorCode == TIMEOUT_ABORTED) {
              Util.consoleLogWarning('handleFetchError', `TIMEOUT_ABORTED: ${errText}`);
            }
            /* do nothing if aborted */
          } else {
            throw errorInfo;
          }
        }
      }

      getFetchArgs (rawOptions) {
        if (!rawOptions || typeof rawOptions !== "object" || Object.keys(rawOptions).length === 0) {
          throw new TypeError("Invalid options argument");
        }
        let options = this.getFilteredOptions(rawOptions);
        const fetchUrl = this.toEscapedURL(options.url, options.path, options.queryParams);
        const method = options.typeValue;
        const fetchArgs = {
          method: method,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
          },
          credentials: "include",
        };
        if (Util.fetchAbortSupported() && options.fetchAbortable && options.fetchTimeoutMillis <= 0) {
          fetchArgs.signal = this.getAbortController().signal;
        }
        if (Util.fetchAbortSupported() && options.fetchTimeoutMillis > 0) {
          fetchArgs.signal = AbortSignal.timeout(options.fetchTimeoutMillis);
        }
        if (method !== 'GET') {
            fetchArgs.body = JSON.stringify(options.data); // JSON.stringify needed here
        }
        this.setupFetchHeadersAndOptions(fetchArgs, options);
        return [fetchUrl, fetchArgs];
      }

      /*
       * Does the actual fetch and handles Promises
       */
      async doFetchCall (rawOptions) {
        if (!rawOptions || typeof rawOptions !== "object" || Object.keys(rawOptions).length === 0) {
          throw new TypeError("Invalid options argument");
        }
        const [fetchUrl, fetchArgs] = this.getFetchArgs(rawOptions);
        const promise = this.performFetch(fetchUrl, fetchArgs);
        return {
                  promise: promise,
                  url: fetchUrl,
                  fetchArgs: fetchArgs
                };
      }

      // debugging (spyOn) hook
      /* istanbul ignore next */
      async nativeFetch (fetchURL, fetchArgs) {
        return fetch(fetchURL, fetchArgs);
      }

      async performFetch (fetchUrl, fetchArgs) {
        let responseData, response;
        try {
          response = await this.nativeFetch(fetchUrl, fetchArgs);
          if (!response.ok) {
            throw response;
          }
          responseData = await this.getFetchResponseData(response, fetchArgs);
        } catch (error) {
          let errorMsg = "";
          let errorType = 'TEXT';
          if (error instanceof Response) {
            [ errorMsg, errorType ] = await this.extractFetchResponseErrorMessage(response, fetchArgs);
          } else {
            errorMsg = error.message;
          }
          await this.handleFetchError(error, errorType, errorMsg, fetchArgs);
        }
        return responseData;
      }

      async getFetchResponseData (response, fetchArgs) {
        let responseData = "";
        // TODO (server-side change):  Server should set response.status to 204 if successful but no content returned.
        // Currently, server always sends 200.
        if (!response.bodyUsed && response.status !== 204 /* NO CONTENT */) {
          if (fetchArgs.headers["Accept"] && fetchArgs.headers["Accept"].indexOf("application/json") >= 0) {
            try {
              responseData = await response.json();
            } catch (error) {
              // Handle the case of status 200 but with no content
              responseData = this.processResponseJSONError(error);
            }
          } else {
            responseData = await response.text();
            if (fetchArgs.headers["Accept"] && fetchArgs.headers["Accept"].indexOf("application/xml") >= 0 && typeof DOMParser === 'function') {
              responseData = this.extractXMLDataFromFetchResponse(responseData);
            }
          }
        }
        return responseData;
      }

      extractXMLDataFromFetchResponse (responseData) {
        let xmlDoc;
        const xmlText = responseData;
        const domParser = new DOMParser();
        xmlDoc = domParser.parseFromString(xmlText, 'text/xml');
        return xmlDoc;
      }

      processResponseJSONError (error) {
        let responseData;
        if (error instanceof SyntaxError || error.message === "Unexpected end of JSON input") {
          responseData = "";
        } else {
          throw error;
        }
        return responseData;
      }

      async extractFetchResponseErrorMessage (response, fetchArgs) {
        let errorMsg = `HTTP Status: ${response.status}`;
        if (response.statusText) {
          errorMsg += ` ${response.statusText}`;
        }
        let errorType = this.getFetchErrorType(fetchArgs);
        if (errorType === 'TEXT') {
          const errorBody = await response.text();
          if (errorBody) {
            errorMsg += ` - ${errorBody}`;
          }
        }
        return [ errorMsg, errorType ];
      }

      getFetchErrorType (fetchArgs) {
        let errorType = 'TEXT';
        if (fetchArgs.headers["Accept"]) {
          if (fetchArgs.headers["Accept"].indexOf("application/json") >= 0) {
            errorType = 'JSON';
          } else if (fetchArgs.headers["Accept"].indexOf("application/xml") >= 0) {
            errorType = 'XML';
          } else {
            errorType = 'TEXT';
          }
        }
        return errorType;
      }

      /**
       * Do Cloud Center logout to make sessionId obsolete on server
       */
      async logout () {
        this.resetQueryQueue();
        this.clearQueryFlag();
        const logoutURL = this.config.getLogoutURL();
        try {
          await this.doFetchCall({url: logoutURL, typeValue: 'GET', cache: false, fetchAbortable: false, fetchTimeoutMillis: Util.defaultTimeout(), mode: 'no-cors'});
        } catch (error) {
          Util.consoleLogWarning("logout", error);
        }
      }

      async validateLogin (authToken) {
        this.setAuthToken(authToken);
        const fullUrl = this.getBaseURL() + "/user/resource/loggedin/";
        const fetchResults = await this.doFetchCall({ url: fullUrl, typeValue: 'GET', cache: false, fetchAbortable: true, fetchTimeoutMillis: Util.defaultTimeout() });
        const promise = fetchResults.promise;
        return promise;
      }

      async startParallelServerCluster (clusterId, restParams) {

        if (!clusterId) {
          throw new TypeError("Invalid cluster id");
        }

        let opts = { typeValue: "PUT", cache: false, contentType: "application/json; charset=utf-8" };
        let baseURL = this.getBaseURL().replace("/v1", "/v2");
        opts.url = `${baseURL }/cluster/${clusterId}/start`;
        if (restParams && typeof restParams === 'object') {
          opts = {...opts, ...restParams};
        }
        const data = await this._eval_fetch(opts);
        return data;
      }

      async getCCAPI (service, type, id, action, inParams, restParams) {
        const opts = this._initCCOpts("GET", service, type, id, action, restParams);
        if (inParams) {
          opts.queryParams = inParams; // JSON.stringify not needed here
        }
        const data = await this._eval_fetch(opts);
        return data;
      }

      async deleteCCAPI (service, type, id, action, inParams, restParams) {
        const opts = this._initCCOpts("DELETE", service, type, id, action, restParams);
        if (inParams) {
          opts.data = {params: inParams}; // JSON.stringify not needed here
          opts.processData=false;
        }
        const data = await this._eval_fetch(opts);
        return data;
      }

      async putCCAPI (service, type, id, action, inParams, restParams) {
        const data = this._putOrPostCCAPI("PUT", service, type, id, action, inParams, restParams);
        return data;
      }

      async postCCAPI (service, type, id, action, inParams, restParams) {
        const data = this._putOrPostCCAPI("POST", service, type, id, action, inParams, restParams);
        return data;
      }

      async _putOrPostCCAPI (method, service, type, id, action, inParams, restParams) {
        let opts = this._initCCOpts(method, service, type, id, action, restParams);
        if (inParams) {
          opts.data = {params: inParams}; // JSON.stringify not needed here
          opts.processData=false;
        }
        opts.contentType="application/json; charset=utf-8";
        opts.type=method;
        opts.dataType="";
        opts.accepts="application/json";
        return await this._eval_fetch(opts);
      }

      async _eval_fetch (opts, retryCount = 0) {
        // setup a dummy promise to use in case of error.
        let result = { promise: new Promise(resolve => {}) };
        try {
            result = await this.doFetchCall(opts);
        } catch (error) {
            if (error && error.status) {
                switch (error.status) {
                    case 500: //Internal Server Error
                    case 502: //Bad Gateway
                    case 503: //Service Unavailable
                    case 504: { //Gateway Timeout
                        // retry after wait
                        ++retryCount;
                        if (retryCount < 3) {
                            const waitTimeMillis = (retryCount * retryCount) * 1000;
                            await new Promise(resolve => setTimeout(resolve, waitTimeMillis));
                            result = await this._eval_fetch(opts, retryCount);
                            /* istanbul ignore next */
                            break;
                        } else {
                          throw error;
                        }
                    }
                    default:
                        throw error;
                }
            } else {
              throw error;
            }
        }
        return result.promise;
      }

      _initCCOpts (method = "GET", service, type, id, action, restParams) {
        if (!service) {
          throw new TypeError("Invalid service argument");
        }
        if (!type) {
          throw new TypeError("Invalid type argument");
        }
        if (!id && action) {
          throw new TypeError("Invalid action argument - id required");
        }

        let opts = { typeValue: method, cache: false, contentType: "application/json; charset=utf-8" };
        if (restParams && typeof restParams === 'object') {
          opts = {...opts, ...restParams};
        }

        opts.url = this.getBaseURL() + `/${service}/${type}/`;
        if (id) {
          opts.url += `${id}` ;

          if (action) { //action requires ID -- TypeError check above, but this is belt-and-suspenders
            opts.url += `/${action}` ;
          }
        }
        return opts;

      }

  // https://cloudcenter-api-integ1.mathworks.com/cluster/list?format=json

      getLegacyParallelAPI (endpoint, id, action, inParams, restParams) {
        let opts = this._initLegacyParallelOpts("GET", endpoint, id, action, restParams);

        if (inParams) {
          opts.queryParams = inParams;
          opts.queryParams.format= 'json';
        } else {
          opts.queryParams = {format:'json'}
        }
        return this._eval_fetch(opts);
      }

      deleteLegacyParallelAPI (endpoint, id, restParams) {
        let opts = this._initLegacyParallelOpts("DELETE", endpoint, id, undefined, restParams);
        return this._eval_fetch(opts);
      }

      putLegacyParallelAPI (endpoint, id, action, inParams, restParams) {
        return this._putOrPostLegacyParallelAPI("PUT", endpoint, id, action, inParams, restParams);
      }

      postLegacyParallelAPI (endpoint, id, action, inParams, restParams) {
        return this._putOrPostLegacyParallelAPI("POST", endpoint, id, action, inParams);
      }

      _putOrPostLegacyParallelAPI (method, endpoint, id, action, inParams, restParams) {
        let opts = this._initLegacyParallelOpts(method, endpoint, id, action, restParams);
        if (inParams) {
          opts.data = {params: inParams}; // JSON.stringify not needed here
          opts.processData=false;
        }
        return this._eval_fetch(opts);
      }

      _initLegacyParallelOpts (method, endpoint, id, action, restParams) {
        if (method.toUpperCase() === "DELETE" && action) {
          throw new TypeError("Invalid action argument - not allowed with DELETE");
        }
        if (method!="GET" && !id) {
          throw new TypeError(`Invalid method argument - ${method} not allowed without ID`);
        }
        if (action && !id) {
          throw new TypeError(`Invalid action ${action} argument - action not allowed without ID`);
        }

        let opts = { typeValue: method, cache: false, contentType: "application/json; charset=utf-8" };
        if (restParams && typeof restParams === 'object') {
          opts = {...opts, ...restParams};
        }

        opts.url = this.getLegacyParallelServicesURL();
        if (endpoint) {
          if ("cluster" === endpoint) {
            opts.url += "/v2";
          }
          opts.url += `/${endpoint}/`;
        }
        if (id) {
          opts.url += `${id}/` ;

          if (action) { //action requires ID -- TypeError check above, but this is belt-and-suspenders
            opts.url += `${action}` ;
          }
        }

        opts.queryParams = {format: 'xml'};
        opts.contentType="";
        opts.type=method;
        opts.dataType="xml";
        opts.accepts="application/xml";
        //tmp integ1 90-day token
        // opts.authToken = "";
        return opts;
      }

    }

    return CloudCenterFetchDAO;
  }); // require
