/* jshint esversion: 8 */
define ([
  "service/cacheRegistry",
  "util"
], function(
  CacheRegistry,
  Util
) {

  class CachedDataService {

    constructor (params) {
      if ( !(params && typeof params === 'object' && Object.keys(params).length > 0)) {
        throw new TypeError('Invalid constructor params');
      }
      this.baseService = null;
      if (params.baseService && typeof params.baseService === 'object') {
        this.baseService = params.baseService;
      }
      if (!this.baseService) {
        throw new TypeError('Invalid baseService argument');
      }
      this.cacheRegistry = new CacheRegistry();
      this.loadedComponents = [];
      this.apiCacheIdDirectory = null;
    }

    getBaseService () { return this.baseService; } // dao
    getPlatformDataService (platform) { return this.getBaseService().getPlatformDataService(platform); }
    getCacheRegistry () { return this.cacheRegistry; }
    getLoadedComponents () { return this.loadedComponents; }
    setLoadedComponents (array) { this.loadedComponents = array; }
    getApiCacheIdDirectory () { return this.apiCacheIdDirectory; }
    setApiCacheIdDirectory (directory) { this.apiCacheIdDirectory = directory; }

    getCacheId (apiPath) {
      let cacheId;
      if (apiPath && typeof apiPath === 'string' && this.getApiCacheIdDirectory().has(apiPath)) {
        cacheId = this.getApiCacheIdDirectory().get(apiPath).cacheId;
      }
      return cacheId;
    }
    clear (apiPath) {
      if ( !(apiPath && typeof apiPath === 'string')) {
        throw new TypeError("Invalid apiPath argument");
      }
      const cacheId = this.getCacheId(apiPath);
      if (cacheId) {
        this.delete(cacheId);
      }
    }
    clearAll () {
      let cachedAPIs = this.getApiCacheIdDirectory().keys();
      for (const apiPath of cachedAPIs) {
        this.clear(apiPath);
      }
    }
    set (cacheId, data) {
      return this.getCacheRegistry().set(cacheId, data);
    }
    get (cacheId) {
      return this.getCacheRegistry().get(cacheId);
    }
    enable (cacheId) {
      return this.getCacheRegistry().enableCache(cacheId);
    }
    disable (cacheId) {
      return this.getCacheRegistry().disableCache(cacheId);
    }
    delete (cacheId) {
      if (Array.isArray(cacheId)){
        for (let i in cacheId) {
          this.getCacheRegistry().delete(cacheId[i]);
        }
      } else {
        return this.getCacheRegistry().delete(cacheId);
      }
    }
    async cacheData (cacheId, component, api, ...fnDataArgs) {
      if (!cacheId || (typeof cacheId !== 'string' && !Array.isArray(cacheId))
          || !this.getCacheRegistry().cacheIsRegistered(cacheId)) {
        throw new TypeError("Invalid cacheId argument");
      }
      if (! (component && typeof component === 'string')) {
        throw new TypeError("Invalid component argument");
      }
      if (! (api && typeof api === 'string')) {
        throw new TypeError("Invalid api argument");
      }
      const context = this.getReferenceToComponent(component, this.getBaseService());
      const fnData = context[api];
      let data;
      if (this.getCacheRegistry().cacheIsEnabled(cacheId)) {
        const baseCache = this.getCacheRegistry().get(cacheId);
        let subCacheId = Util.hash(cacheId, component, api);
        if (fnDataArgs.length) {
          subCacheId = Util.hash(cacheId, component, api, ...fnDataArgs);
        }
        data = baseCache.get(subCacheId);
        if (!data) {
          data = await fnData.bind(context)(...fnDataArgs);
          baseCache.set(subCacheId, data);
        }
      } else {
        data = await fnData.bind(context)(...fnDataArgs);
      }
      return data;
    }

    init (components, apisToCache) {
      // remove any effects from previous call to init
      this.removeAllComponents();
      if (components && Array.isArray(components) && components.length) {
        for (const component of components) {
          // throws if invalid
          this.validateComponent(component);
        }
        // throws if invalid data
        this.setApiCacheIdDirectory(this.generateCacheLoadClearMap(apisToCache, components));
        // dynamically create local APIs that are cached, or not, and map to the baseService.
        this.generateServiceAPIs(components, this.getApiCacheIdDirectory());
      }
    }

    removeComponent (component) {
      if ( !(component && typeof component === 'string')) {
        throw new TypeError("Invalid component argument");
      }
      const pieces = this.splitPath(component);
      const rootComponent = pieces[0];
      let base = this.getReferenceToComponent(component, this);
      if (base) {
        for (const api in base) {
          const apiPath = `${component}/${api}`;
          if (this.getApiCacheIdDirectory().has(apiPath)) {
            this.getApiCacheIdDirectory().delete(apiPath);
          }
        }
        delete this[rootComponent];
      }
    }

    removeAllComponents () {
      if (this.getLoadedComponents().length) {
        const components = this.getLoadedComponents();
        for (let component of components) {
          this.removeComponent(component);
        }
        this.setLoadedComponents([]);
      }
    }

    buildComponentPath (componentPath) {
      if (!componentPath || typeof componentPath !== 'string') {
        throw new TypeError(`Invalid componentPath argument`);
      }
      const pieces = this.splitPath(componentPath);
      let base = this;
      try {
        this.loopOverPathPieces(base, pieces, true);
      } catch (error) {
        throw new TypeError(`Invalid componentPath argument`);
      }
    }

    getReferenceToComponent (path, container) {
      if (!path || typeof path !== 'string') {
        throw new TypeError(`Invalid path argument`);
      }
      if (!container || typeof container !== 'object') {
        throw new TypeError(`Invalid container argument`);
      }
      const pieces = this.splitPath(path);
      let base = container;
      try {
        base = this.loopOverPathPieces(base, pieces, false);
      } catch (error) {
        return null;
      }
      return base;
    }

    apiPathExists (apiPath, baseContainer) {
      let pathExists = true;
      if (!apiPath || typeof apiPath !== 'string') {
        throw new TypeError(`Invalid apiPath argument`);
      }
      if (!baseContainer || typeof baseContainer !== 'object') {
        throw new TypeError(`Invalid baseContainer argument`);
      }
      const pieces = this.splitPath(apiPath);
      const api = pieces.pop(); // get the last item which should be a method name
      // at this point pieces should just consist of a set of object names that make up the path.
      let base = baseContainer;
      try {
        base = this.loopOverPathPieces(base, pieces, false);
      } catch (error) {
        pathExists = false;
      }
      if (pathExists) {
        if (!base[api] || typeof base[api] !== 'function') {
          pathExists = false;
        }
      }
      return pathExists;
    }

    validateComponent (component) {
      if (!component || typeof component !== 'string') {
        throw new TypeError(`Invalid component argument: ${component}`);
      }
      // cached component ref should not exist yet
      if (this.getReferenceToComponent(component, this)) {
        throw new TypeError(`Invalid component argument: ${component}`);
      }
      const pieces = this.splitPath(component);
      let base = this.getBaseService();
      try {
        this.loopOverPathPieces(base, pieces);
      } catch (error) {
        throw new TypeError(`Invalid component argument: ${component}`);
      }
    }

    componentsContainApi (apiPath, components) {
      let APIisContainedInAProvidedComponent = false;
      if (!apiPath || typeof apiPath !== 'string') {
        throw new TypeError("Invalid apiPath argument");
      }
      const pieces = this.splitPath(apiPath);
      const api = pieces.pop(); // get the last item which should be a method name
      if (pieces.length === 0) {
        throw new TypeError("Invalid apiPath argument");
      }
      const componentPath = pieces.join('/');
      if (components && Array.isArray(components) && components.length) {
        for (let component of components) {
          if (component.indexOf('/') === 0) {
            component = component.replace('/', '');
          }
          if (componentPath === component) {
            APIisContainedInAProvidedComponent = true;
            break;
          }
        }
      }
      return APIisContainedInAProvidedComponent;
    }

    generateCacheLoadClearMap (apisToCache, components) {
      const cacheLoadClearMap = new Map();
      if (apisToCache && Array.isArray(apisToCache) && apisToCache.length) {
        for(let apiToCache of apisToCache) {
          if (typeof apiToCache === 'object' && "set" in apiToCache && "clear" in apiToCache) {
            if (apiToCache.set && typeof apiToCache.set === 'string') {
              try {
                const apiPathInComponents = this.componentsContainApi(apiToCache.set, components);
                const baseServiceIncludesAPI = this.apiPathExists(apiToCache.set, this.getBaseService());
                if (!apiPathInComponents || !baseServiceIncludesAPI) {
                  throw new TypeError(`Invalid set api value: ${apiToCache.set}`);
                }
              } catch (error) {
                throw new TypeError(`Invalid set api value: ${apiToCache.set}`);
              }
              const setPieces = this.splitPath(apiToCache.set);
              const cacheId = Util.hash(...setPieces);
              cacheLoadClearMap.set(setPieces.join('/'), {type: "set", cacheId: cacheId});
              if (apiToCache.clear) {
                if (typeof apiToCache.clear === 'string') {
                  this.createCacheLoadClearMapEntry(apiToCache.clear, components, cacheId, cacheLoadClearMap);
                } else if (Array.isArray(apiToCache.clear) && (apiToCache.clear.length > 0)) {
                  for (const api of apiToCache.clear) {
                    this.createCacheLoadClearMapEntry(api, components, cacheId, cacheLoadClearMap);
                  }
                }
              }
            }
          }
        }
      }
      /*
        Map with entries like: <component>/<api>: {type: "set", cacheId: <cacheId}
        or <component>/<api>: {type: "clear", cacheId: <cacheId}
      */
      return cacheLoadClearMap;
    }

    createCacheLoadClearMapEntry (api, components, cacheId, cacheLoadClearMap) {
      let clearValue = null;
      if (!api || typeof api !== 'string') {
        throw new TypeError("Invalid api argument");
      }
      if (!components || !(Array.isArray(components))) {
        throw new TypeError("Invalid components argument");
      }
      if (!cacheId || (typeof cacheId !== 'string' && !Array.isArray(cacheId))) {
        throw new TypeError("Invalid cacheId argument");
      }
      if (!cacheLoadClearMap || !(cacheLoadClearMap instanceof Map)) {
        throw new TypeError("Invalid cacheLoadClearMap argument");
      }
      try {
        const apiPathInComponents = this.componentsContainApi(api, components);
        const baseServiceIncludesAPI = this.apiPathExists(api, this.getBaseService());
        if (!apiPathInComponents || !baseServiceIncludesAPI) {
          throw new TypeError(`Invalid clear api value: ${api}`);
        }
      } catch (error) {
        throw new TypeError(`Invalid clear api value: ${api}`);
      }
      const clearPieces = this.splitPath(api);
      clearValue = clearPieces.join('/'); // get rid of any leading '/'.
      if (cacheLoadClearMap.has(clearValue)){
        cacheLoadClearMap.set(clearValue, {type: "clear", cacheId: [cacheId].concat(cacheLoadClearMap.get(clearValue).cacheId)})
      } else {
        cacheLoadClearMap.set(clearValue, {type: "clear", cacheId: cacheId});
      }
    }

    generateServiceAPIs (components, cacheLoadClearMap) {
      for (const component of components) {
        this.buildComponentPath(component);
        this.getLoadedComponents().push(component);
        let base = this.getReferenceToComponent(component, this.getBaseService());
        const apiList = this.getAPIList(base);
        let cachedBase = this.getReferenceToComponent(component, this);
        for (const api of apiList) {
          const apiPath = `${component}/${api}`;
          if (cacheLoadClearMap.size && cacheLoadClearMap.has(apiPath)) {
            const typeAndCacheId = cacheLoadClearMap.get(apiPath);
            if (typeAndCacheId.type === "set") {
              if (this.getCacheRegistry().cacheIsRegistered(typeAndCacheId.cacheId)) {
                this.getCacheRegistry().deregisterCache(typeAndCacheId.cacheId);
              }
              this.getCacheRegistry().registerCache(typeAndCacheId.cacheId, true);
              cachedBase[api] = async function (...fnArgs) {
                let result;
                try {
                  result = await this.cacheData(typeAndCacheId.cacheId, component, api, ...fnArgs);
                } catch (error) {
                  CachedDataService.handleWorkflowEventError(error)
                }
                return result;
              }.bind(this);
            }
            if (typeAndCacheId.type === "clear") {
              cachedBase[api] = async function (...fnArgs) {
                let result;
                try {
                  result = await base[api](...fnArgs);
                  await this.delete(typeAndCacheId.cacheId);
                } catch (error) {
                  CachedDataService.handleWorkflowEventError(error)
                }
              return result;
              }.bind(this);
            }
          } else {
            cachedBase[api] = async function (...fnArgs) {
              let result;
              try {
                result = await base[api](...fnArgs);
              } catch (error) {
                CachedDataService.handleWorkflowEventError(error)
              }
              return result;
            }.bind(this);
          }
        }
      }
    }

    getAPIList (base) {
      let apiList = Object.getOwnPropertyNames(base).filter(apiName => typeof base[apiName] === 'function' && base.hasOwnProperty(apiName));
      if (Object.getPrototypeOf(base).constructor !== Object) {
        const filter = (apiName) => {
          let isApi = false;
          if (typeof base[apiName] === 'function' &&
            apiName !== 'constructor' &&
            Object.getPrototypeOf(base).hasOwnProperty(apiName)) {
            isApi = true;
          }
          return isApi;
        };
        apiList = apiList.concat(Object.getOwnPropertyNames(Object.getPrototypeOf(base)).filter(filter));
      }
      return apiList;
    }

    splitPath (path) {
      if (!path || typeof path !== 'string') {
        throw new TypeError("Invalid path argument");
      }
      const pieces = path.split('/');
      if (!pieces[0]) {
        pieces.shift(); // remove first item if it's ""
      }
      return pieces;
    }

    loopOverPathPieces (container, pieces, create = false) {
      let base = container;
      const len = pieces.length;
      for (let i = 0; i < len; i++) {
        if (!base[pieces[i]] && create) {
          base[pieces[i]] = {};
        }
        if (!base[pieces[i]] || typeof base[pieces[i]] !== 'object') {
          throw new TypeError(`Item not found or is not an object`);
        }
        this.generateGetterMethod(base, pieces[i]);

        base = base[pieces[i]];
      }
      return base;
    }

    generateGetterMethod (base, componentName) {
      if (base && typeof base === 'object' && componentName && typeof componentName === 'string') {
        const getterName = `get${Util.toTitleCase(componentName)}Service`;
        base[getterName] = function () { return base[componentName]; };
      }
   }

    static shouldIgnoreError (error) {
      const wasAborted = (error && typeof error === 'object' && error.message === 'Action aborted.');
      const shouldAbort = (
        (error && typeof error === 'object')
        && (error.status && error.status === 200)
        && (error.errorCode && error.errorCode === 'CLIENT_PARSE_ERROR')
      );
      return (wasAborted || shouldAbort);
    }

    static handleWorkflowEventError(error){
      if (!CachedDataService.shouldIgnoreError(error)) {
        const innerErrorObject = Util.extractWorkflowEventError(error)
        if (innerErrorObject) {
          const innerErrorDetails = innerErrorObject.details;
          throw { errorCode:innerErrorObject.code, errorLevel: innerErrorObject.level, message: innerErrorDetails };
        } else {
          throw error
        }
      }
    }

  }

  return CachedDataService;
});
