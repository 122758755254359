// Expose the class either via AMD, CommonJS or the global object
// NOTE: Prerequisites _, $, Util, I18NStringResource, DojoString need to be
// loaded BEFORE loading this file, if not using AMD/requirejs.
//
(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    define([
      "underscore",
      "jquery",
      "util",
      "dojo/i18n!nls/cloudCenterStringResource",
      "dojo/string"
    ], function( _, $, Util, I18NStringResource, DojoString) { return factory( _, $, Util, I18NStringResource, DojoString);});
  } else if (typeof exports === 'object') {
    module.exports = factory();
  } else {
    root.APSClient = factory();
  }
}(this, function( _, $, Util, I18NStringResource, DojoString) {

  const APSClient = function(config) {
    if (!config ||
        !config.getApsAuthURL || !config.getApsAuthURL() ||
        !config.getApsLoginURL || !config.getApsLoginURL() ||
        !config.getApsBrokerURL || !config.getApsBrokerURL() ) {
      throw new Error("APSClient given invalid construction arguments.");
    }
    this.apsToken = null;
    this.userId = "";
    this.WS = null;
    this.isSecure = location.protocol === "https:";
    this.webSocket = null;
    this.authUrl = config.getApsAuthURL();
    this.loginUrl = config.getApsLoginURL();
    this.brokerUrl = config.getApsBrokerURL();
    this.keepAliveHandle = null;
    this.instanceGuid = Util.getInstanceGUID();
    this.subscriptionCount = 0;
    // bind 'this' in the listed methods to the current 'this', even when called by other modules.
    _.bindAll(this, "start", "initialize", "receiveMessage", "openConnection", "closeConnection", "stop", "keepAlive",
                    "stopKeepAlive", "getInstanceGUID", "getUserId", "handleReceivedError", "handleReceivedData",
                    "processOpenConnectionResponse", "startKeepAlive", "processUnsubscribeResponse", "processReceivedMessage",
                    "handleAjaxSuccess", "handleAjaxError", "getSubscriptionCount", "incrementSubscriptionCount",
                    "decrementSubscriptionCount", "sendApsMessage");
  };
  APSClient.prototype = {

    getInstanceGUID: function() {
      return this.instanceGuid;
    },

    getUserId: function() {
      return this.userId;
    },

    getSubscriptionCount: function() {
      return this.subscriptionCount;
    },

    incrementSubscriptionCount: function() {
      this.subscriptionCount++;
    },

    decrementSubscriptionCount: function() {
      if (this.subscriptionCount > 0) {
        this.subscriptionCount--;
      }
    },

    log: function(msg) {
      if (("console" in window) && window.console.log && msg && msg.length) {
        console.log(msg);
      }
    },

    getWebSocketClass: function() {
      var socketClass = null;
      var fn = window || this; // get the global object (in a browser it's window)
      if (("WebSocket" in fn)) {
        socketClass = WebSocket;
      }
      return socketClass;
    },

    handleAjaxSuccess: function (responseData, status, xhr) {
      var gotApsToken = false;
      var errorMessage = null;

      if (!responseData || ( // no response data at all
           (!responseData.faults || !responseData.faults.length) &&  // or no faults and
           (!responseData.messages) )) // no messages
      {
        errorMessage = I18NStringResource.apsInvalidResponse;
      }

      if (!errorMessage && responseData && responseData.faults && responseData.faults.length) {
        errorMessage = responseData.faults[0].message;
      }

      if (!errorMessage && responseData && responseData.messages) {
        let apsLoginResponse = null;

        if (apsLoginResponse && apsLoginResponse.messageFaults && apsLoginResponse.messageFaults.length) {
          errorMessage = apsLoginResponse.messageFaults[0].message;
        }

        // Version 2.0.0
        if (responseData.messages.createTokenResponse && responseData.messages.createTokenResponse.length){
          let apsTokenInfo = responseData.messages.createTokenResponse[0];
          this.apsToken = apsTokenInfo.token;
          gotApsToken = true;
          this.userId = apsTokenInfo.apsUserId;
          this.start();
        } else {
          errorMessage = I18NStringResource.apsInvalidToken;
        }

      }
      if (!gotApsToken && errorMessage) {
        Util.notify('ERROR', errorMessage, null, 'notification:aps');
      }
    },

    handleAjaxError: function (errObj, errType, errText) {
      var message = "APS Client Error: ";
      if (errObj.status === 0 && errObj.statusText == "error") {
        message += "Refer to JavaScript console.";
      } else {
        message += errObj.status + "  " + errObj.statusText;
      }
      Util.notify('ERROR', message, null, 'notification:aps');
    },

    initialize: function(token) {
      this.WS = this.getWebSocketClass();
      if (!this.WS) {
        throw new Error("WebSocket not supported.");
      }
      var context = this;
      if (!token) {
        throw new Error("Missing authorization token.");
      }
      var requestData = this.getApsRequestData(this.getInstanceGUID(), token);
      var headers = {};
      headers["X-FORWARDED-HOST"] = this.loginUrl;
      var ajaxArgs = {
       url: this.authUrl,
       type: 'post',
       data: JSON.stringify(requestData),
       contentType: 'application/json',
       headers: headers,
       success: this.handleAjaxSuccess,
       error: this.handleAjaxError
     };
     $.ajax(ajaxArgs);
    },

    getApsRequestData: function(guid, token) {
      var data;
      if (guid && guid.length && token && token.length) {
        data = {
          uuid: guid,
          version: "2.0.0",
          messages: {
            createToken: [{
              credentials: {
                token: {
                  tokenType: "MWA",
                  value: token
                }
              },
              context: "pubsub"
            }]
          }
        };
      }
      return data;
    },

    parseMessage: function(message) {
      var m = {};
      if (message && message.length) {
        message.replace(/([^=]+)=([^=]+)\n/g, function($0, param, value) {
          m[param] = value;
        });
      }
      return m;
    },

    handleReceivedError: function(data) {
      if (data && data.error) {
        this.webSocket.close();
        Util.notify('ERROR', data.error, null, 'notification:aps');
      }
    },

    sendApsMessage: function(message) {
      if (this.webSocket) {
        this.webSocket.send(JSON.stringify(message));
      } else {
        this.log("APS webSocket not initialized.");
      }
    },

    getTopics: function() {
      var topic = ["mockTopic"];
      return topics;
    },

    createOpenConnectionMessage: function() {
      var openConnectionMessage = this.createMessage("openConnection");
      return openConnectionMessage;
    },

    createSubscribeMessage: function(topic) {
      var subscribeMessage;
      if (topic && topic.length) {
        subscribeMessage = this.createMessage("subscribe", topic);
      }
      return subscribeMessage;
    },

    createUnsubscribeMessage: function(topic) {
      var unsubscribeMessage;
      if (topic && topic.length) {
        unsubscribeMessage = this.createMessage("unsubscribe", topic);
      }
      return unsubscribeMessage;
    },

    subscribeToTopics: function(topics) {
      var subscribeMessage;
      // 0: connecting; 1: open; 2: closing; 3: closed
      if (topics && topics.length && this.webSocket && (this.webSocket.readyState === 0 || this.webSocket.readyState === 1)) {
        for (var i = 0, len = topics.length; i < len; i++) {
          subscribeMessage = this.createSubscribeMessage(topics[i]);
          this.sendApsMessage(subscribeMessage);
        }
      }
    },

    subscribeToAllTopics: function() {
      var topics = this.getTopics();
      this.subscribeToTopics("mockTopic");
    },

    unsubscribeFromTopics: function(topics) {
      var unsubscribeMessage;
      // 0: connecting; 1: open; 2: closing; 3: closed
      if (topics && topics.length && this.webSocket && (this.webSocket.readyState === 0 || this.webSocket.readyState === 1)) {
        for (var i = 0, len = topics.length; i < len; i++) {
          unsubscribeMessage = this.createUnsubscribeMessage(topics[i]);
          this.sendApsMessage(unsubscribeMessage);
        }
      }
    },

    unsubscribeFromAllTopics: function() {
      var topics = this.getTopics();
      this.unsubscribeFromTopics("mockTopic");
    },

    processOpenConnectionResponse: function(data) {
      if (data.openConnectionResponse ) {
        if (data.openConnectionResponse.messageFaults && data.openConnectionResponse.messageFaults.length) {
          Util.notify('ERROR', "APS initialization error: " + data.openConnectionResponse.messageFaults[0].message, null, 'notification:aps');
          return;
        }
        this.subscribeToAllTopics();
      }
    },

    processFaults: function(data) {
      if (data.faults && data.faults.length) {
        Util.notify('ERROR', "APS initialization error: " + data.faults[0].message, null, 'notification:aps');
        return;
      }
    },

    processUnsubscribeResponse: function() {
      if (this.webSocket && !this.getSubscriptionCount()) {
        this.webSocket.close();
      }
    },

    startKeepAlive: function(data) {
      var context = this;
      var ping = function() { context.keepAlive(); };
      if (data && data.subscribeResponse && data.subscribeResponse.topic === "/" + this.getUserId() + "mockTopic") {
        this.keepAliveHandle = setInterval(ping, 30000);
      }
    },

    stopKeepAlive: function() {
      if (this.keepAliveHandle) {
        clearInterval(this.keepAliveHandle);
        this.keepAliveHandle = null;
      }
    },

    getOperationDataFromMessage: function(m, topic) {
      var operationData = null;
      if (m && (!m.originId || m.originId !== this.getInstanceGUID())) {
        if (m.operation) {
          operationData = {
            eventApplicationName: "",
            actionName: "",
            origPath: "",
            changedPath: "",
            triggerUpdateEvent: false,
            triggerStorageUpdateEvent: false,
            affectedPage: "both"
          };
          switch (m.operation) {
          }
        }
      }
      return operationData;
    },

    getActionMessage: function(actionName, actionItem, actionParent) {
      var i18nString = "";
      var index = "apsAction";

      switch (actionName) {
        default:
          index += actionName;
      }
      if(I18NStringResource[index]) {
        i18nString = DojoString.substitute(I18NStringResource[index], [actionItem, actionParent]);
      } else {
        this.log("Missing I18N string for: " + index);
      }
      return i18nString;
    },

    processReceivedMessage: function(data) {
      if (data.message) {
        var m = this.parseMessage(data.message);
        var topic = data.topic || "";
        var operationData = null;

        if (!m.originId || m.originId !== this.getInstanceGUID()) {
          operationData = this.getOperationDataFromMessage(m, topic);
        }

        if (operationData && operationData.triggerUpdateEvent) {
          var actionItem = Util.getFileNameFromPath(operationData.changedPath);
          var actionParent = Util.getParentFolderFromPath(operationData.changedPath);
          var actionMsg = this.getActionMessage(operationData.actionName, actionItem, actionParent);

          $.event.trigger('refresh:aps', {
                                            path: operationData.changedPath,
                                            origPath: operationData.origPath,
                                            page: operationData.affectedPage,
                                            application: operationData.eventApplicationName
                                          });
          Util.notify('ERROR', actionMsg, null, 'notification:aps');
        }

      }
    },

    handleReceivedData: function(data) {
      if (data && typeof data === "object") {

        if (data.faults && data.faults.length) {
          this.processFaults(data);
        }

        if (data.openConnectionResponse ) {
          this.processOpenConnectionResponse(data);
        }

        if (data.subscribeResponse) {
          this.incrementSubscriptionCount();
          if (data.subscribeResponse.topic === "/" + this.getUserId() + "mockTopic") {
            this.startKeepAlive(data);
          }
        }

        if (data.unsubscribeResponse) {
          this.decrementSubscriptionCount();
          this.processUnsubscribeResponse();
        }

        if (data.message) {
          this.processReceivedMessage(data);
        }
      }
    },

    receiveMessage: function(event) {
      var data;
      try {
        data = JSON.parse(event.data);
      } catch (e) {
        this.log("JSON parse error: " + e);
      }
      // Handle errors
      if (data && data.error) {
        this.handleReceivedError(data);
        return;
      } else {
        this.handleReceivedData(data);
      }
    },

    openConnection: function(e) {
        // Web Socket is connected, open service channel
        var openChannelMessage = this.createOpenConnectionMessage();
        // 0: connecting; 1: open; 2: closing; 3: closed
        if (this.webSocket && (this.webSocket.readyState === 0 || this.webSocket.readyState === 1)) {
          this.sendApsMessage(openChannelMessage);
        }
    },

    closeConnection: function(e) {
      this.stopKeepAlive();
    },

    stop: function() {
      this.stopKeepAlive();
      this.unsubscribeFromAllTopics();
      if (this.webSocket) {
        this.webSocket.close();
      }
    },

    createMessage: function(msgType, topic) {
      var message;
      if (msgType && msgType.length ) {
        switch (msgType) {
          case "subscribe":
          case "unsubscribe":
            if (topic && topic.length) {
              message = {};
              message[msgType] = {
                 topic: "/" + this.getUserId() + topic,
                 version: "1.0.0",
                 uuid : this.getUserId()
              };
            }
            break;
          case "openConnection":
            message = {
              version: "2.0.0",
              token: this.apsToken
            };
            message[msgType] = {
             clientInfo: {
               serviceName: "cloudcenter",
               instanceId: this.getInstanceGUID()
             },
             messageFormat: {
               protocol: "pubsub",
               version: "1.0.3"
             }
           };
            break;
        }
      }
      return message;
    },

    start: function() {
      if (!this.WS) {
        throw new Error("WebSocket not supported.");
      }
      if (this.isSecure) {
        this.webSocket = new this.WS("wss:\/\/" + this.brokerUrl);
      } else {
        this.webSocket = new this.WS("ws:\/\/" + this.brokerUrl);
      }
      this.webSocket.onopen = this.openConnection;
      this.webSocket.onclose = this.closeConnection;
      this.webSocket.onmessage = this.receiveMessage;
    },

    keepAlive: function() {
      var context = this;
      var pingData = JSON.stringify({data: "keepAlive ping"});
      try {
        this.webSocket.send(pingData);
      } catch (e) {
        context.stop();
        context.start();
      }
    },

    isAlive: function() {
      var alive = false;
      // 0: connecting; 1: open; 2: closing; 3: closed
      if (this.webSocket && (this.webSocket.readyState === 0 || this.webSocket.readyState === 1)) {  // 1 means socket is open and connected
        alive = true;
      }
      return alive;
    }

  };

  return APSClient;
})); // require
