/* jshint esversion: 8 */
define([
  "underscore",
  "jquery",
  "dao/abstractDao",
  "dojo/i18n!nls/cloudCenterStringResource",
  "dojo/string",
  "util"
], function( _, $, AbstractDAO, I18NStringResource, DojoString, Util ) {

  /**
   * Implementation of AbstractDAO interface using Cloud Center Microservice.
   * Uses config object to get needed URL Parameters
   *
   * throws TypeError if config is missing or not what is expected.
   */
  class CloudCenterDAO extends AbstractDAO {
    constructor(config) {
      if (!config || typeof config !== 'object' ||
        !config.getMicroServiceURL ||
        typeof config.getMicroServiceURL !== "function" ||
        !config.getAWSAccountID ||
        typeof config.getAWSAccountID !== "function" ||
        typeof config.getLegacyParallelServicesURL !== "function") {

        throw new TypeError("Incompatible config file parameter.");
      }
      super();
      /* istanbul ignore next */
      let useFakeLogin = (config.isFakeLoginEnabled && typeof config.isFakeLoginEnabled === "function" && config.isFakeLoginEnabled());
      /* istanbul ignore next */
      let fakeUserName = (useFakeLogin && config.getFakeUser && typeof config.getFakeUser === "function") ? config.getFakeUser() : "";
      this.config = config;
      this.authToken = null;
      this.sessionId = null;
      this.loginData = null;
      this.originId = null;
      this.sessionListener = null;
      this.microServiceBaseUrl = config.getMicroServiceURL();
      this.awsAccountID = config.getAWSAccountID();
      this.legacyParallelServicesURL = config.getLegacyParallelServicesURL();
      this.useFakeLogin = useFakeLogin;
      this.fakeUserName = fakeUserName;
      this.sessionExpiredCallQueue = [];
      this.queryQueue = [];
      this.queryWaiting = false;
      this.clientString = "cloudcenter";
      this.initialize();
    }

    initialize() {
      _.bindAll(this, "processNextQueryInQueue",
                      "getFilteredOptions",
                      "handleAjaxError",
                      "retryQueuedCalls",
                      "getAjaxArgs",
                      "toEscapedURL");
      // Right now we only use HTML5 local storage. If that's not supported, we do no storing of values.
      /* istanbul ignore next */
      this.htmlLocalStorage = ( (typeof(Storage) !== "undefined") &&
                                (typeof(localStorage) === "object") &&
                                (typeof(localStorage.getItem) === "function") ) ? true : false;  // can we use HTML5 local (or session) storage?
    }

    getBaseURL() { return this.microServiceBaseUrl; }
    getLegacyParallelServicesURL() { return this.legacyParallelServicesURL; }
    getAWSAccountID() { return this.awsAccountID; }
    isFakeLoginEnabled() { return this.useFakeLogin; }
    getFakeUser() { return this.fakeUserName; }
    setSessionListener(listener) { this.sessionListener = listener; }
    getSessionListener() { return this.sessionListener; }
    setAuthToken(authToken) { this.authToken = authToken; }
    setSessionId(sessionId) { this.sessionId = sessionId; }
    getAuthToken() { return this.authToken; }
    setOriginId(originId) { this.originId = originId; }
    getSessionId() { return this.sessionId; }
    getOriginId() { return this.originId; }
    getLoginData() { return this.loginData; }
    setLoginData(data) { this.loginData = data; }
    getUserId() { return (this.getLoginData()) ? this.getLoginData().userID : ""; }
    setUserId(userId) { /* do nothing */ }
    enqueue(item) { return this.sessionExpiredCallQueue.push(item); }
    dequeue() { return this.sessionExpiredCallQueue.shift(); }
    queueSize() { return this.sessionExpiredCallQueue.length; }
    setQueryFlag() { this.queryWaiting = true; }
    clearQueryFlag() { this.queryWaiting = false; }
    resetQueryQueue() { this.queryQueue = []; }
    queryFlagIsSet() { if (this.queryWaiting) { return true; } else { return false; } }
    setClientString(clientString) { this.clientString = clientString; }
    getClientString() { return this.clientString; }
    useHtmlLocalStorage() {
      return this.htmlLocalStorage;
    }

    processNextQueryInQueue() {
      if (this.queryQueueSize() > 0) {
        let nextItem = this.dequeueQuery();
        $.ajax(nextItem.ajaxArgs);
      } else {
        this.clearQueryFlag();
      }
    }
    enqueueQuery(item) {
      if (!this.queryQueue.some(function(x) { return x === item;})) {
        item.promise.always(this.processNextQueryInQueue);
        this.queryQueue.push(item);
      }
      return this.queryQueueSize();
    }
    dequeueQuery() { return this.queryQueue.shift(); }
    queryQueueSize() { return this.queryQueue.length; }

    toEscapedURL(url, path, queryParams) {
      if (!url) {
        throw new TypeError("Invalid url argument");
      }
      let encodedPath = (path? encodeURIComponent(path) : '');
      if (encodedPath) {
        encodedPath = encodedPath.replace(/%2F/gi,'/');
        if (encodedPath.charAt(0) !== "/") {
          encodedPath = "/" + encodedPath;
        }
      }
      let fullURL = encodeURI(url);
      if (encodedPath) {
        fullURL = fullURL + encodedPath;
      }

      queryParams = queryParams || {};
      // Add usage metrics support
      queryParams.clientString = this.getClientString();
      // Turn each property of queryParams into list of "[key]=[value]" strings, where both [key] and [value]
      // have been URL encoded
      let parts = _.map(_.pairs(queryParams), function(pair) {
          return encodeURIComponent(pair[0]) + "=" + encodeURIComponent(pair[1]);
      });
      // Join the list together, delimiting each pair with a "&"
      let queryString = parts.join("&");

      if (queryString) {
          fullURL = fullURL + "?" + queryString;
      }
      return fullURL;
    }

    getFilteredOptions(options) {
      let filteredOptions = null;
      /* setup options */
      // options are either passed in, or use the default values
      let defaults = {
          url: null,
          useHeaders: true,
          typeValue: 'get',
          dataType: 'json',
          timeout: 180000,
          data: {},
          cache: false,
          queryParams: {},
          contentType: 'application/json',
          accepts: 'application/json; charset=utf-8'
      };
      if (typeof options == 'object') {
        filteredOptions = {...defaults, ...options}; // Merge objects: override defaults with any passed in values
      } else {
        filteredOptions = defaults;
      }
      // Add client APS originId
      if (this.getOriginId()) {
        filteredOptions.queryParams.originId = this.getOriginId();
      }
      return filteredOptions;
    }

    setupAjaxHeadersAndOptions(ajaxArgs, rawOptions) {
      if (!ajaxArgs || typeof ajaxArgs !== "object" || Object.keys(ajaxArgs).length === 0) {
        throw new TypeError("Invalid ajax argument object parameter");
      }
      /* istanbul ignore next */
      let options = rawOptions || {};
      /* setup headers */
      let headers = {};
      if ('accepts' in options && options.accepts) {
        headers.Accept = options.accepts;
      }
      if ('contentType' in options && options.contentType) {
        headers['Content-Type'] = options.contentType;
      }
      if ('customHeaderName' in options && 'customHeaderValue' in options) {
        headers[options.customHeaderName] = options.customHeaderValue;
      }
      if ('authToken' in options) {
        headers['x-mw-authentication'] = options.authToken;
      }
      if (this.isFakeLoginEnabled() && this.getFakeUser().length) {
        headers['x-mw-fakelogin'] = this.getFakeUser();
      }
      if (options.useHeaders) {
        ajaxArgs.headers = headers;
      }
      if (options.dataType) {
        ajaxArgs.dataType = options.dataType;
      }
      if ('processData' in options) {
        ajaxArgs.processData = options.processData;
      }
      if ('cache' in options) {
        ajaxArgs.cache = options.cache;
      }
      if ('xhr' in options) {
        ajaxArgs.xhr = options.xhr ;
      }
    }

    extractErrorInfo(errObj, errType, errText) {
      let errorInfo = {errorCode: '', message: '', status: 0};

      // Extraact the error object from the server response.
      // It contains an error code and a text message.
      // If not error object, use the more generic passed in error text.
      let text = errText || '';
      let errorCode = null;
      let wasAborted = false;
      if (errObj && typeof errObj === "object" && Object.keys(errObj).length !== 0) {
        errorInfo.status = errObj.status;
        if (errObj.getAllResponseHeaders) {
          wasAborted = !errObj.getAllResponseHeaders();
        }
      }

      let responseJson;
      try {
        responseJson = (errObj && errObj.responseText) ? errObj.responseText : null;
        let errorObj = null;
        if (responseJson && typeof responseJson === "string") {
          errorObj = JSON.parse(responseJson);
          if (errorObj && errorObj.errors && errorObj.errors.length) {
            let error = errorObj.errors[0];
            text = error.message;
            errorCode = error.code;
          } else if (errorObj && Array.isArray(errorObj) && errorObj.length) {
            let error = errorObj[0];
            text = error;
            errorCode = error;
            if(errorCode === `core.aws.iam.error.invalidclienttokenid`) {
              text = I18NStringResource.AWSCreateRole_invalidTokens;
            } else if (errorCode === `core.aws.iam.error.expiredtoken`) {
              text = I18NStringResource.AWSCreateRole_expiredTokens;
            }
          } else if (errorObj && errorObj.err) {
            text = JSON.stringify(errorObj.err);
            errorCode = "UNKNOWN";
          }
        }
      } catch(parseError) {
        if (errObj && typeof errObj === "object" && Object.keys(errObj).length !== 0 && errObj.getResponseHeader && errObj.getResponseHeader("Content-Type") && errObj.getResponseHeader("Content-Type").toLowerCase().includes("xml")) { // Parse CC1's error xml response
          let responseXML = (errObj && errObj.responseText) ? errObj.responseText : null;
          let parser = new DOMParser();
          let resp = parser.parseFromString(responseXML, "application/xml");
          if (resp.getElementsByTagName("code").length) {
            errorCode = resp.getElementsByTagName("code")[0].innerHTML
          }
          if (resp.getElementsByTagName("message").length) {
            text = resp.getElementsByTagName("message")[0].innerHTML
          }
        } else {
          errorCode = 'CLIENT_PARSE_ERROR';
          text = "Invalid response from server";
          Util.consoleLogWarning("extractErrorInfo", responseJson);
          Util.consoleLogWarning("extractErrorInfo", parseError.stack);
        }
      } finally {
        errorInfo.errorCode = errorCode;
        if(errorCode !== 'SESSION_EXPIRED' && errorCode !== 'SESSION_NOT_FOUND' && !text && wasAborted) {
          errorInfo.message = I18NStringResource.actionAborted;
        } else if (errorCode === "USAGE_QUOTA_EXCEEDED") {
          errorInfo.message = I18NStringResource.userQuotaStorageFull;
        } else {
          errorInfo.message = text;
        }
      }
      return errorInfo;
    }

    retryQueuedCalls() {
      let callItem = null;
      let currentSessionId = this.getSessionId();
      if (currentSessionId) {
        while ((callItem = this.dequeue())) {
          if (callItem && callItem.ajaxArgs && Object.keys(callItem.ajaxArgs).length !== 0 && callItem.ajaxArgs.headers) {
            // update sessionId in headers before resending
            if (callItem.ajaxArgs.headers['x-mw-gds-session-id']) {
              callItem.ajaxArgs.headers['x-mw-gds-session-id'] = currentSessionId;
            }
            // rerun failed call with updated headers
            $.ajax(callItem.ajaxArgs);
          }
        }
      }
    }

    updateLoginInformation(sessionRenewalData) {
      if (sessionRenewalData && (typeof sessionRenewalData === "object")) {
        if (sessionRenewalData.sessionId) {
          this.setSessionId(sessionRenewalData.sessionId);
        }
        if (("loginProfile" in sessionRenewalData) && sessionRenewalData.loginProfile) {
          if (sessionRenewalData.loginProfile.mwaToken) {
            this.setAuthToken(sessionRenewalData.loginProfile.mwaToken);
          }
          this.setLoginData({
            firstName: sessionRenewalData.loginProfile.firstName,
            lastName: sessionRenewalData.loginProfile.lastName,
            userID: sessionRenewalData.loginProfile.userId,
            emailAddress: sessionRenewalData.loginProfile.emailAddress,
            token: sessionRenewalData.loginProfile.mwaToken
          });
        }
      }
    }

    clearLoginInformation() {
      this.setAuthToken(null); // assume authToken no good.
      this.setSessionId(null);
      this.setLoginData(null);
    }

    handleAjaxError (errObj, errType, errText, promise, ajaxArgs) {
      if (!ajaxArgs || typeof ajaxArgs !== "object" || Object.keys(ajaxArgs).length === 0) {
        throw new TypeError("Invalid ajaxArgs argument");
      }
      if (!promise || typeof promise !== "object" || !promise.reject) {
        throw new TypeError("Invalid promise argument");
      }
      let errorInfo = this.extractErrorInfo(errObj, errType, errText);
      if (errorInfo && (errorInfo.errorCode === 'AUTH_TOKEN_EXPIRED')) {
        this.clearLoginInformation();
        promise.reject(errorInfo);
        // Notify those interested that our auth token is probably expired (auth manager)
        // cause a login to happen
        if (this.sessionListener) {
          this.sessionListener.trigger('AUTH_TOKEN_EXPIRED');
        }
      } else if (errorInfo && (errorInfo.errorCode === 'SESSION_EXPIRED' || errorInfo.errorCode === 'SESSION_NOT_FOUND')) {
        // try to renew session and if successful, retry original request.
        // If not successful, reject original request.
        promise.reject(errorInfo);
        // Notify those interested that our auth token is probably expired (auth manager)
        // cause a login to happen
        if (this.sessionListener) {
          this.sessionListener.trigger('AUTH_TOKEN_EXPIRED');
        }
      } else {
        promise.reject(errorInfo);
      }
    }

    getAjaxArgs(rawOptions, promise) {
      let context = this;
      if (!rawOptions || typeof rawOptions !== "object" || Object.keys(rawOptions).length === 0) {
        throw new TypeError("Invalid options argument");
      }
      if (!promise || typeof promise !== "object" || typeof promise.resolve !== "function") {
        throw new TypeError("Invalid promise argument");
      }
      let options = this.getFilteredOptions(rawOptions);
      let ajaxArgs = {
        url: this.toEscapedURL(options.url, options.path, options.queryParams),
        type: options.typeValue,
        data: options.data,
        contentType: options.contentType,
        xhrFields: { withCredentials:true },
        success: function (responseData, status, xhr) {
          promise.resolve(responseData, xhr);
        },
        error: function(errObj, errType, errText) { context.handleAjaxError(errObj, errType, errText, promise, ajaxArgs); }
      };
      this.setupAjaxHeadersAndOptions(ajaxArgs, options);
      return ajaxArgs;
    }

    /*
     * Does the actual AJAX call and handles Promises
     */
    doAjaxCall(rawOptions, prepOnly) {
      if (!rawOptions || typeof rawOptions !== "object" || Object.keys(rawOptions).length === 0) {
        throw new TypeError("Invalid options argument");
      }
      let promise = $.Deferred();
      let ajaxArgs = this.getAjaxArgs(rawOptions, promise);
      if (!prepOnly) {
        $.ajax(ajaxArgs);
      }
      return {
                promise: promise,
                ajaxArgs: ajaxArgs
              };
    }

    /**
     * Do Cloud Center logout to make sessionId obsolete on server
     */
    logout() {
      this.resetQueryQueue();
      this.clearQueryFlag();
    }

    validateLogin(authToken) {
      this.setAuthToken(authToken);
      let fullUrl = this.getBaseURL() + "/user/resource/loggedin/";
      let ajaxCallResults;
      ajaxCallResults = this.doAjaxCall({ url: fullUrl, typeValue: 'GET', cache: false });
      let promise = ajaxCallResults.promise;
      let context = this;
      return promise;
    }

    async startParallelServerCluster (clusterId, restParams) {

      if (!clusterId) {
        throw new TypeError("id required");
      }
      let opts = { typeValue: "PUT", cache: false, contentType: "application/json" };
      if (restParams && typeof restParams === 'object') {
        opts = {...opts, ...restParams};
      }
      let baseURL = this.getBaseURL().replace("/v1", "/v2");
      opts.url = `${baseURL}/cluster/${clusterId}/start`;
      if (restParams && typeof restParams === 'object') {
        opts = {...opts, ...restParams};
      }
      opts.contentType="";
      opts.type="PUT";
      opts.dataType="";
      opts.accepts="";
      return this._evalCCAjax(opts);
    }

    async getCCAPI(service, type, id, action, inParams, restParams) {
      let opts = this._initCCOpts("GET", service, type, id, action, restParams);

      if (inParams) {
        opts.queryParams = inParams;
      }

      let lastErr;
      for (let i = 1; i <= 2; i++) {
        try {
          let data = await this._evalCCAjax(opts);
          return Promise.resolve(data);
        } catch (e) {
          if (e && typeof e === "object") {
            switch (e.status) {
              case 500: //Internal Server Error
              case 502: //Bad Gateway
              case 503: //Service Unavailable
              case 504: { //Gateway Timeout
                lastErr = e;
                let retryInMilli = (i*i)*1000;
                await new Promise(resolve => setTimeout(resolve, retryInMilli));
                break;
              }
              default:
                return Promise.reject(e);
            }
          } else {
            return Promise.reject(e);
          }
        }
      }
      return Promise.reject(lastErr);

    }

    deleteCCAPI(service, type, id, action, inParams, restParams) {
      let opts = this._initCCOpts("DELETE", service, type, id, action, restParams);
      if (inParams) {
        opts.data = JSON.stringify({params: inParams});
        opts.processData=false;
      }

      return this._evalCCAjax(opts);
    }

    putCCAPI(service, type, id, action, inParams, restParams) {
      return this._putOrPostCCAPI("PUT", service, type, id, action, inParams, restParams);
    }

    postCCAPI(service, type, id, action, inParams, restParams) {
      return this._putOrPostCCAPI("POST", service, type, id, action, inParams, restParams);
    }

    _putOrPostCCAPI(method, service, type, id, action, inParams, restParams) {
      let opts = this._initCCOpts(method, service, type, id, action, restParams);

      if (inParams) {
        opts.data = JSON.stringify({params: inParams});
        opts.processData=false;
      }
      opts.contentType="";
      opts.type=method;
      opts.dataType="";
      opts.accepts="";

      return this._evalCCAjax(opts);
    }

    _evalCCAjax(opts) {
      return this.doAjaxCall(opts).promise;
    }

    _initCCOpts(method, service, type, id, action, restParams) {
      if (!service) {
        throw new TypeError("Invalid service argument");
      }
      if (!type) {
        throw new TypeError("Invalid type argument");
      }
      if (!id && action) {
        throw new TypeError("Invalid action argument - id required");
      }

      let opts = { typeValue: method, cache: false, contentType: "application/json" };
      if (restParams && typeof restParams === 'object') {
        opts = {...opts, ...restParams};
      }

      opts.url = this.getBaseURL() + `/${service}/${type}/`;
      if (id) {
        opts.url += `${id}` ;

        if (action) { //action requires ID -- TypeError check above, but this is belt-and-suspenders
          opts.url += `/${action}` ;
        }
      }
      return opts;

    }

// https://cloudcenter-api-integ1.mathworks.com/cluster/list?format=json

    getLegacyParallelAPI(endpoint, id, action, inParams, restParams) {
      let opts = this._initLegacyParallelOpts("GET", endpoint, id, action, restParams);

      if (inParams) {
        opts.queryParams = inParams;
        opts.queryParams.format= 'json';
      } else {
        opts.queryParams = {format:'json'}
      }
      return this._evalCCAjax(opts);
    }

    deleteLegacyParallelAPI(endpoint, id, restParams) {
      let opts = this._initLegacyParallelOpts("DELETE", endpoint, id, null, restParams);

      return this._evalCCAjax(opts);
    }

    putLegacyParallelAPI(endpoint, id, action, inParams, restParams) {
      return this._putOrPostLegacyParallelAPI("PUT", endpoint, id, action, inParams, restParams);
    }

    postLegacyParallelAPI(endpoint, id, action, inParams, restParams) {
      return this._putOrPostLegacyParallelAPI("POST", endpoint, id, action, inParams, restParams);
    }

    _putOrPostLegacyParallelAPI(method, endpoint, id, action, inParams, restParams) {

      let opts = this._initLegacyParallelOpts(method, endpoint, id, action, restParams);
      if (inParams) {
        opts.data = JSON.stringify({params: inParams});
        opts.processData=false;
      }
      return this._evalCCAjax(opts);
    }

    _initLegacyParallelOpts(method, endpoint, id, action, restParams) {
      if (method.toUpperCase() === "DELETE" && action) {
        throw new TypeError("Invalid action argument - not allowed with DELETE");
      }
      if (method!="GET" && !id) {
        throw new TypeError(`Invalid method argument - ${method} not allowed without ID`);
      }
      if (action && !id) {
        throw new TypeError(`Invalid action ${action} argument - action not allowed without ID`);
      }

      let opts = { typeValue: method, cache: false, contentType: "application/json" };
      if (restParams && typeof restParams === 'object') {
        opts = {...opts, ...restParams};
      }

      opts.url = this.getLegacyParallelServicesURL();
      if (endpoint) {
        if ("cluster" === endpoint) {
          opts.url += "/v2";
        }
        opts.url += `/${endpoint}/`;
      }
      if (id) {
        opts.url += `${id}/` ;

        if (action) { //action requires ID -- TypeError check above, but this is belt-and-suspenders
          opts.url += `${action}` ;
        }
      }

      opts.queryParams = {format: 'xml'};
      opts.contentType="";
      opts.type=method;
      opts.dataType="xml";
      opts.accepts="application/xml";
      //tmp integ1 90-day token
      // opts.authToken = "";
      return opts;
    }

  }

  return CloudCenterDAO;
}); // require
